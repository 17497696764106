html,
body {
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@font-face {
  font-family: 'GucciSansPro-Medium';
  src: url('./resources/fonts/GucciSansPro-Medium.otf');
  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'GucciSansPro-Book';
  src: url('./resources/fonts/GucciSansPro-Book.otf');
  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'myfont';
  src: url('./resources/fonts/GucciSans-Bold.otf');
}

::-webkit-scrollbar {
  width: 8px;
  margin-top: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #a0a0a0;
}

.link {
  color: black !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flex {
  display: flex;
}

.fit {
  width: fit-content;
}

.pointer {
  cursor: pointer
}

.align_items {
  align-items: center;
}

/* LOADER */

.loader-cont {
  margin-top: 100px;
}

.loader1 {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
  z-index: 1000;
}

.loader1:before {
  content: '';
  width: 40px;
  height: 5px;
  background: #686868;
  position: absolute;
  top: 60px;
  left: 4px;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
  background-size: cover;
  background-repeat: no-repeat;
}

.loader1:after {
  content: '';
  width: 50px;
  height: 50px;
  background-image: url('./resources/img/favicon2.png');
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump 2s linear infinite;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes jump {

  6% {
    transform: translateY(9px) rotate(22.5deg);
  }

  12% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
  }

  19% {
    transform: translateY(9px) rotate(67.5deg);
  }

  25% {
    transform: translateY(0) rotate(90deg);
  }

  31% {
    transform: translateY(9px) rotate(115deg);
  }

  38% {
    transform: translateY(18px) scale(1, .9) rotate(135deg);
  }

  44% {
    transform: translateY(9px) rotate(155deg);
  }

  50% {
    transform: translateY(0) rotate(180deg);
  }

  56% {
    transform: translateY(9px) rotate(195deg);
  }

  63% {
    transform: translateY(18px) scale(1, .9) rotate(230deg);
  }

  69% {
    transform: translateY(9px) rotate(255deg);
  }

  75% {
    transform: translateY(0) rotate(270deg);
  }

  81% {
    transform: translateY(9px) rotate(295deg);
  }

  88% {
    transform: translateY(18px) scale(1, .9) rotate(315deg);
  }

  94% {
    transform: translateY(9px) rotate(345deg);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

@keyframes shadow324 {

  0%,
  100% {
    transform: scale(1.2, 1);
  }

  50% {
    transform: scale(1, 1);
  }
}

/* NAVBAR */

.navbar {
  background-color: #fff;
  /* width: calc(100% - 40px); */
  width: 100%;
  padding: 5px 20px;
  align-items: center;
  height: 50px;
  position: absolute;
  top: 0;
  z-index: 99;
  justify-content: center;
  text-align: -webkit-center;
}

.navbar_container {
  width: 1440px;
  align-items: center;
}

.navbar_logo {
  background-image: url("./resources/img/gucci_logo.png");
  height: 20px;
  width: 116px;
  background-size: 115px;
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  position: absolute;
  left: calc(50% - 57.5px);
  top: 16px;
}

#contact_us {
  font-family: 'GucciSansPro-Book';
  text-decoration: underline;
  display: inline-block;
  position: relative;
}

#contact_us {
  --n: 4;
  /* adjust this to control the delay */

  text-decoration: none;
  display: inline-block;
  background: linear-gradient(to right,
      #000 calc(100%/var(--n)),
      transparent 0 calc((var(--n) - 1)*100%/var(--n)),
      #000 0) bottom right/ calc(var(--n)*100%) 1px no-repeat;
}

#contact_us:hover {
  background-position: bottom left;
  transition: 0.6s;
  /* adjust this to control the total duration */
}

.navbar_icon_container {
  margin-left: auto;
  align-items: center;
}

.navbar_icon {
  margin: 0 10px;
}

/* FOOTER */

.footer {
  /*width: 1440px;*/
  width: 1200px;
}

.footer_mobile {
  display: none;
}

.footer_container {
  background-color: #000;
  /* height: 40vh; */
  /* place-self: center; */
  padding: 20px 50px;
  display: -webkit-box;
  -webkit-box-pack: center;
}

#footer_logo {
  background-image: url("./resources/img/gucci_logo_bianco.png");
  height: 320px;
  /* width: 100%; */
  /* width: 1000px; */
  background-size: 100%;
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  left: calc(50% - 40px);
  max-width: 100%;
}

.footer_columns_container {
  /*width: calc(100% - 100px);*/
  margin-top: 40px;
}

.title_menu_footer {
  color: #555555;
  text-transform: uppercase;
  font-family: "GucciSansPro-Medium";
  font-size: 12px;
}

.submenu_footer {
  font-size: 14px;
  color: #cfcfcf;
  margin: 10px 0;
  text-decoration: underline;
}

.submenu_footer:hover {
  color: #bdbdbd;
}

#help_column {
  width: 25%;
}

#company_column {
  width: 25%;
}


.half_column {
  width: 50%;
}

/* DLL */

.dropdown-toggle {
  background-color: #000 !important;
  color: #555555 !important;
  border: none;
  border-bottom: #555555 1px solid;
  box-shadow: none !important;
  text-align: -webkit-left;
  font-family: "GucciSansPro-Book";
}

.dropdown-menu.show {
  display: block;
  transform: translate(-70px, 38px) !important;
}

.dropdown-toggle::after {
  margin-top: 0px !important;
  margin-left: 10px !important;
}

.btn-success {
  color: #fff;
  background-color: transparent !important;
  border-color: #555555 !important;
}

.btn {
  padding: .375rem 0 !important;
  ;
}

/* SEARCH */

.search_input_footer {
  border: 0;
  background-color: #000;
  height: 40px;
  text-align: left;
  color: #555;
  border-radius: 0px;
  font-family: "GucciSansPro-Book";
  width: 80%;
  border-bottom: 1px solid #555;
}

.search_input_footer:focus,
input:focus {
  outline: none;
}

.search_input_footer::placeholder {
  font-family: "GucciSansPro-Book";
  border-color: none !important;
  border: 0 !important;
}

.search_input_footer::after {
  border: 0 !important;
}

.search_input_footer_arrow {
  margin-left: -10px;
  margin-top: 2px;
  margin-right: 20px;
  cursor: pointer;
}

#license_string_footer {
  margin-top: 20px;
  font-family: "GucciSansPro-Book";
  color: #cfcfcf;
  font-size: 12px;
}

.footer_logo {
  background-color: #555;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  margin-right: 40px;
}

#gucci_equilibrium_logo {
  background-image: url("./resources/img/gucci_equilibrium.png");
  opacity: 0.6;
  width: 40px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 30px;
}

#gucci_equilibrium_logo:hover {
  opacity: 1;
}

#gucci_app_logo {
  background-image: url("./resources/img/guicci_app.png");
  opacity: 0.6;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 30px;
}

#gucci_app_logo:hover {
  opacity: 1;
}

#gucci_garden_logo {
  background-image: url("./resources/img/gucci_garden.png");
  opacity: 0.6;
  width: 80px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 30px;
}

#gucci_garden_logo:hover {
  opacity: 1;
}

#gucci_osteria_logo {
  background-image: url("./resources/img/gucci_osteria.png");
  opacity: 0.6;
  width: 90px;
  height: 45px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 100px;
}

#gucci_osteria_logo:hover {
  opacity: 1;
  padding: .375rem 0 !important;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }

}

/* CENTER COMPONENT */

/* SEARCH */
.search_component {
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 100vh;
}

.search {
  text-align: center;
  padding-top: 60px;
  width: 80%;
  margin: auto;
  text-align: -webkit-center;
}

.search_input_title_container {
  width: 555px;
}

.search_container {
  /*margin: auto;*/
  padding: 2% 50px;
  margin-top: 25vh;
}

.search_title {
  font-family: 'GucciSansPro-Medium';
  text-transform: uppercase;
  font-size: 16px;
  color: black;
  margin-bottom: 50px;
  text-align: left;
}

.search_input_container {
  text-align: left;
  /*float: left;*/
  margin-bottom: 50px;
  width: 100%;
  display: grid;
}

.search_input_title {
  font-family: 'GucciSansPro-Book';
  font-size: 13px;
  color: #606060;
  float: left;
}

.search_input_cont {
  position: relative;
}

.search_input {
  float: left;
  background: transparent;
  width: 455px;
  border: none;
  border-bottom: 1px solid #606060;
  font-size: 20px;
  text-align-last: left;
  font-family: 'GucciSansPro-Book';
  color: #606060;
}

.search_input:focus {
  border-width: 0 !important
}

.search_input_icon {
  position: absolute;
  left: 95%;
  top: 6px;
}

.product_search_info {
  text-align: left;
  font-family: 'GucciSansPro-Book';
  color: #000000;
  font-size: 15px;
}

/* HOME COMPONENT */
.home_component {
  margin-top: 100px;
  /*margin-bottom: 50px; */
  min-height: 100vh;
}

.product_section_container {
  display: flex;
  place-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: calc(100vh - 100px);
}

.product_section {
  text-align: -webkit-center;
  height: 100vh;
}

.product_description_container {
  width: 1440px;
}

.left_description {
  width: 50%;
  padding-left: 25px;
  padding-top: 100px;
  padding-right: 25px;
  text-align: -webkit-left;
}

.right_carousel {
  width: 50%;
}

.history_container {
  margin: 50px 0;
}


.product_auth {
  font-family: 'GucciSansPro-Medium';
  text-transform: uppercase;
  /*font-size: 0.8em;*/
  color: black;
  margin-bottom: 10px;
  text-align: left;
}

.product_name {
  text-transform: uppercase;
  /* font-size: 0.8em; */
  margin-bottom: 24px;
  color: black;
  font-family: 'GucciSansPro-Medium';
  font-size: 24px;
  line-height: 24px;
}

.product_code {
  text-transform: uppercase;
  /*font-size: 0.6em;*/
  margin-bottom: 10px;
  color: black;
  font-family: 'GucciSansPro-Book';
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 24px;
}

.product_description {
  /*font-size: 0.7em;*/
  font-size: 12px;
  margin-bottom: 24px;
  color: black;
  font-family: 'GucciSansPro-Book';
  margin-right: 35px;
}

.menu_item_line {
  padding: 5px 0;
}

.point_list_container {
  margin-bottom: 24px;
}

.point_list {
  font-size: 16px;
  font-family: 'GucciSansPro-Medium';
  margin-right: 10px;
  animation: rotating_back 1s;
  cursor: pointer;
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotating_back {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(0);
  }
}

.point_list:hover {
  animation: rotating 1s;

}

.loader1 {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
  z-index: 1000;
}

.loader-cont {
  margin-top: 100px;
  height: 300px;
}

.loader1:before {
  content: '';
  width: 40px;
  height: 5px;
  background: #686868;
  position: absolute;
  top: 60px;
  left: 4px;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
  background-size: cover;
  background-repeat: no-repeat;
}

.loader1:after {
  content: '';
  width: 50px;
  height: 50px;
  background-image: url('./resources/img/favicon2.png');
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump 2s linear infinite;
  background-size: cover;
  background-repeat: no-repeat;
}

#nfc_stamp_logo {

  background-image: url("./resources/img/nfc_stamp.png");
  height: 70px;
  width: 70px;
  min-width: 70px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  float: left;
}

.point_name {
  font-size: 12px;
  font-family: 'GucciSansPro-Medium';
  cursor: pointer;
}

.hr_top_container {
  display: none;
}

.hr_bottom_container {
  width: 90%;
}

.nfc_container {
  display: flex;
  align-items: center;
}

.nfc_text_container {
  font-family: 'GucciSansPro-Book';
  font-size: 12px;
  width: 400px;
}

.export_button {
  background-color: #000;
  color: white;
  width: 100px;
  font-family: 'GucciSansPro-Book';
  text-align: -webkit-center;
  font-size: 13px;
  margin-top: 15px;
  margin-bottom: 15px;
  transition: transform 0.2s ease;
  padding: 10px 0px;
}

.export_button:active {
  transform: scale(0.96);
}

.product_code_link {
  text-transform: uppercase;
  font-size: 0.7em;
  margin-bottom: 10px;
  color: black;
  font-family: 'GucciSansPro-Book';
  cursor: pointer;
  text-decoration: underline;
}

.product_image_card {
  min-width: 95%;
  height: 600px;
  /*background-size: cover;*/
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


.composition_table_row,
.composition_table_cell {
  border: 1px solid #000;
  font-size: 14px;
  font-family: 'GucciSansPro-Book';
  padding: 5px 10px;
}

.composition_table_header {
  border: 1px solid #000;
  font-family: 'GucciSansPro-Medium';
  padding: 5px 10px;
}

#france_packaging {
  background-image: url("./resources/img/france_packaging.png");
  width: 200px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 60px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 100px;
}

#japan_packaging {

  background-image: url("./resources/img/japan_packaging.png");
  width: 200px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 60px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 100px;
}

.traceability_what {
  font-family: 'GucciSansPro-Medium';
}

.traceability_where {
  margin-left: 10px;
  font-family: 'GucciSansPro-Book';
}

.scroll_cont {
  display: -webkit-flex;
  white-space: nowrap;
  width: 100%;
  /* z-index: 1; */
  overflow: auto;
  padding-bottom: 25px;
  place-content: center;
}

.scroll_cont::-webkit-scrollbar {
  height: 3px;
  margin-top: 2px;
}

/* Track */
.scroll_cont::-webkit-scrollbar-track {
  background: #d9d9d9;
  margin: 15vw
}

/* Handle */
.scroll_cont::-webkit-scrollbar-thumb {
  background: #000000;
}

/*tab*/
@keyframes righttoleft {
  0% {
    left: 100%;
  }

  100% {
    left: 0;
    display: none;
  }
}

@keyframes lefttoright {
  from {
    left: 0%;
    width: 100%;
    display: block;
  }


  to {
    left: 100%;
    width: 0px;
    display: none !important;
    height: 0;
  }

}

@keyframes bottomtotop {
  0% {
    top: 100%;
    width: 100%;
  }

  100% {
    top: 0%;
    width: 100%;
  }
}

@keyframes toptobottom {
  0% {
    top: 0;
    width: 100%;
  }

  100% {
    width: 100%;
    top: 100%;
  }
}

.tab {
  width: 100%;
  display: block;
  background-color: #ffffff8c;
  /*position: absolute;*/
  position: fixed !important;
  height: 100vh;
  /*top: 0;*/
  top: -20px;
  transition: 0.5s;
  /*animation: bottomtotop 1s;*/
  animation: righttoleft 1s;
  z-index: 5;
}

.closed_tab {
  background-color: #ffffff8c;
  position: fixed;
  height: 100vh;
  top: 0;
  animation: lefttoright 1s forwards;
}


.close_button {
  position: relative;
  text-align: right;
  top: 40px;
  z-index: 99;
  margin-top: 40px;
  margin-right: 30px;
}

/**/
.left_tab {
  width: 20%;
  height: 100vh;
  background-color: #7e7979bd;
  backdrop-filter: blur(8px);
}

.right_tab {
  width: 80%;
  /* width: 100%;*/
  height: 110vh;
  background-color: #fff;
}

.right_tab_container {
  display: flex;
}


.composition_text {
  font-family: 'GucciSansPro-Book';
  font-size: 14px;
}

.tab_title {
  font-family: 'GucciSansPro-Medium';
  margin-top: 50px;
}

.tab_menu {
  font-family: 'GucciSansPro-Medium';
  margin-top: 20px;
  cursor: pointer;
}

.tab_menu_selected {
  text-decoration: underline;
}

.left_menu_tab {
  width: 30%;
  padding: 6% 6%;
  margin-top: 180px;
}

.right_menu_tab {
  width: 70%;
  margin: 7% 2% 2% 0%;
  position: relative;
}

.tab_title {
  text-transform: uppercase;
  font-size: 25px;
}

.right_menu_tab_cont {
  position: absolute;
  top: 0;
  animation: righttoleft 1s;
  min-width: 100%;
  text-align: -webkit-left;
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 10px;
}

.right_menu_tab_cont::-webkit-scrollbar {
  height: 3px;
  margin-top: 2px;
}

/* Track */
.right_menu_tab_cont::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
.right_menu_tab_cont::-webkit-scrollbar-thumb {
  background: #000000;
}

/* VIDEO */

/*
.video_container {
  margin: 100px 0;

}*/

#video_title {
  font-family: 'GucciSansPro-Medium';
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

#video_subtitle {
  font-family: 'GucciSansPro-Book';
  text-align: center;
  margin-bottom: 20px;
}

/* CAROUSEL */

.also_like_component {
  display: grid;
  place-content: center;
}

.also_like {
  width: 1440px;
}

.also_like_container {
  padding-top: 50px;
  padding-bottom: 50px;
}

#also_like_title {
  font-family: 'GucciSansPro-Medium';
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 20px;
}

.carousel_product {
  opacity: 0.6;
  width: 160px;
  min-width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.carousel_product:hover {
  opacity: 1;
}

.also_like_carousel {
  place-content: center;
  padding-bottom: 10px;
  min-width: 100%;
}

.also_like_carousel::-webkit-scrollbar {
  height: 3px;
  margin-top: 2px;
}

/* Track */
.also_like_carousel::-webkit-scrollbar-track {
  background: #d9d9d9;
  margin: 44vw;
}

/* Handle */
.also_like_carousel::-webkit-scrollbar-thumb {
  background: #000000;
}

@media (max-width: 1440px) {


  .navbar_container {
    width: 100%;
  }

  .product_section_container {
    width: 100%;
  }


  .footer {
    width: 100%;
  }


  .also_like {
    width: 100%;
  }

}


@media (max-width: 1000px) {

  /* NAVBAR */

  .footer_web {
    display: none;
  }

  .footer_mobile {
    display: block;
  }

  #footer_logo_mobile {
    background-image: url("./resources/img/gucci_logo_bianco.png");
    height: 170px;
    /* width: 100%; */
    /* width: 1000px; */
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;
    background-position: center;
    left: calc(50% - 40px);
    max-width: 100%;
  }

  #contact_us {
    display: none;
  }

  .navbar_logo {
    left: 20px;
    top: 15px;
  }

  /* SEARCH */

  .search {
    padding-top: 0px;
  }

  .search_input_title_container {
    width: 100%;
  }

  .search_title {
    font-size: 14px;
  }

  .search_container {
    width: auto;
    padding: 2% 20px;
  }

  .search_input_container {
    width: 100%;
  }

  .search_input_title {
    font-size: 12px;
  }

  .search_input {
    width: 100%;
  }

  .search_input_icon {
    width: 15px;
  }

  /* PRODUCT SECTION */

  .product_section_container {
    display: block !important;
    place-content: center;
    align-content: center;
    flex-wrap: nowrap !important;
    height: auto !important;
  }

  .nfc_global_container {
    justify-content: center;
  }

  .nfc_container {
    display: grid;
  }

  .nfc_text_container {
    width: 100%;
    text-align: -webkit-center;
  }


  .hr_top_container {
    display: block;
  }

  .hr_bottom_container {
    display: none;
  }

  .product_description_container {
    width: 100%;
  }

  .product_image_card {
    min-width: 90%;
    height: 500px;
  }

  .left_description {
    margin-top: 400px;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .tab {
    animation: bottomtotop 1100ms;
  }

  /*
  */
  .closed_tab {
    z-index: 1;
    animation: toptobottom 1100ms forwards !important;
  }

  .export_button {
    width: 100%;
    padding: 15px 0;
    height: auto;
    font-size: 15px;
  }

  #video_title {
    font-size: 32px;
    margin-top: 60px;
    padding: 0 16px;
  }

  .product_auth {
    font-size: 12px;
  }


  .product_name,
  .product_code {
    font-size: 20px;
  }

  .right_carousel {
    position: absolute;
    top: 50px;
  }

  .product_section {
    display: grid;
    height: auto;
  }

  .right_carousel_container {
    padding: 20px;
  }

  .right_carousel {
    width: calc(100% - 32px);
    padding: 0 16px;
  }

  .right_carousel::-webkit-scrollbar-track {
    margin: 44vw;
  }

  /* CAROUSEL */

  .also_like_component {
    display: block;
    place-content: baseline;
  }

  .also_like_container {
    padding-left: 0px;
    background-color: #F6F5F5;
  }

  .also_like_carousel::-webkit-scrollbar-track {
    background: #d9d9d9;
    margin: 30vw;
  }

  .also_like_carousel_container {
    padding: 0 16px;
  }

  .also_like_carousel {
    /*
    margin-left: 15px;
    margin-right: 15px;
    */
    place-content: baseline;
    padding-right: 40px;
  }


  #also_like_title {
    font-size: 24px;
    text-decoration: none;
  }

  .carousel_product {
    opacity: 1;
    width: 355px;
    min-width: 355px;
    height: 430px;
    margin-right: 30px;
    border: 1px solid #d0d0d0;
    background-color: white;
    background-size: contain;
  }


  /* FOOTER */

  .search_input_footer_mobile {
    color: #fff !important;
    background-color: #000;
  }

  .search_input_footer_mobile {
    border: 0;
    background-color: #000;
    height: 40px;
    text-align: left;
    color: #555;
    border-radius: 0px;
    font-family: "GucciSansPro-Book";
    width: 80%;
    border-bottom: 1px solid #555;
  }

  .search_input_footer_mobile:focus,
  input:focus {
    outline: none;
  }

  .search_input_footer_mobile::placeholder {
    font-family: "GucciSansPro-Book";
    border-color: none !important;
    border: 0 !important;
  }

  .search_input_footer_mobile::after {
    border: 0 !important;
  }

  #search_input_footer_arrow_mobile {
    margin-left: -10px;
    margin-top: 2px;
    margin-right: 20px;
    cursor: pointer;
  }

  /* MOBILE FOOTER */

  .search_input_footer {
    margin-left: 10px;
    width: 100%;
  }

  .search_input_footer_arrow {
    margin-left: -20px;
    margin-top: 2px;
    margin-right: 0;
  }

  .menu_item_row {
    margin-bottom: 15px;
  }

  .menu_item_mobile_footer_container {
    margin-top: 30px;
  }

  .menu_item_mobile_footer {
    color: #fafafa;
    font-family: "GucciSansPro-Book";
  }

  .menu_icon_mobile {
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 20px;
    height: 20px;
    min-width: 20px;
  }

  #live_chat_icon {
    background-image: url("./resources/img/live_chat.png");
  }

  #text_us_icon {
    background-image: url("./resources/img/text_us.png");
  }

  #call_us_icon {
    background-image: url("./resources/img/call_us.png");
  }

  #book_appointment_icon {
    background-image: url("./resources/img/book_appointment.png");
  }

  .collapse_container {

    width: 100%;
    border-bottom: 1px solid #555;
  }

  .collapse_button_input_footer_open {
    background-color: #000;
    border: 0;
    height: 40px;
    text-align: left;
    color: #555555;
    border-radius: 0px;
    font-family: "GucciSansPro-Medium";
    text-transform: uppercase;
    width: 100%;
  }

  .collapse_button_input_footer_open::placeholder {
    color: #555;
  }

  .collapse_button_input_footer_closed {
    background-color: #000;
    border: 0;
    height: 40px;
    text-align: left;
    color: #555555;
    border-radius: 0px;
    font-family: "GucciSansPro-Medium";
    text-transform: uppercase;
    width: 100%;
    border-bottom: 1px solid #555;
  }

  .collapse_button_input_footer_closed::placeholder {
    color: #555;
  }

  .collapse_arrow {
    margin-left: -20px;
    margin-top: 2px;
  }

  .collapse_arrow_opened {
    margin-left: -20px;
    margin-top: 2px;
  }

  /* ECO FEATURES */

  .right_menu_tab_cont {
    max-height: 75vh;
  }

  .right_menu_tab {
    width: 90%;
    margin-top: 0;
    margin-left: 20px;
  }

  .right_tab {
    width: 100%;
  }

  .left_tab {
    display: none;
  }

  .close_button {
    position: absolute;
    top: 90vh;
    left: 45%;
    margin-right: 0;
  }

  .right_tab_container {
    display: block;
    margin-top: 30px;
  }

  .composition_table_container {
    /*
    height: 80vh;
    overflow-y: auto;
    */
    margin-top: 0px;
  }


  .composition_table_row,
  .composition_table_cell {
    padding: 5px;
  }

  .composition_table_header {
    padding: 5px;
  }

  .left_menu_tab {
    display: flex;
    margin-top: 0px;
    width: 85%;
    overflow-x: auto;
    margin-bottom: 15px;
    padding: 6% 10% 3% 6%;
  }

  .left_menu_tab::-webkit-scrollbar {
    height: 0px;
    margin-top: 2px;
  }

  /* Track */
  .left_menu_tab::-webkit-scrollbar-track {
    background: #d9d9d9;
    margin: 10vw;
  }

  /* Handle */
  .left_menu_tab::-webkit-scrollbar-thumb {
    background: #000000;
  }

  /*
  */
  /*.tab_menu_selected {
    color: #000 !important;
    text-decoration: none;
    border-bottom: 1px solid #000 !important;
    padding: 0 15px 8px 15px !important;
    transition: transform 0.2s ease;
  }

  .tab_menu_selected {
    transform: scale(0.96);
    border-bottom: 1px solid #fd0000 !important;
  }*/

  @keyframes expand {
    from {
      transform: scaleX(0);
    }

    to {
      transform: scaleX(1);
      border-bottom: solid 1px #000000;
    }
  }



  .tab_menu_selected {
    font-family: 'GucciSansPro-Book';
    display: inline-block;
    position: relative;
    display: block;
    color: #666;
    margin-top: 20px;
    text-decoration: none;
  }


  .tab_menu_selected:after {
    position: absolute;
    content: '';
    border-bottom: 1px solid #d2d7da;
    width: 100%;
    transform: translateX(-50%);
    bottom: 0px;
    left: 0;
    animation: 0.5s expand forwards;
  }

  .tab_menu {
    inline-size: max-content;
    /*margin-right: 20px;*/
    padding: 0 15px 8px 15px !important;
    color: #d9d9d9;
    white-space: nowrap;
    border-bottom: 1px solid #d9d9d9;
  }

  /* VIDEO */

  .video_player_container {
    padding: 0 16px;
  }

}

@media (max-width: 400px) {

  .carousel_product {
    width: 320px;
    min-width: 320px;
  }

  .also_like_carousel {
    padding-right: 50px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

  .close_button {
    position: absolute;
    top: 78vh;
    left: 45%;
    margin-right: 0;
  }

  .right_menu_tab_cont {
    max-height: 62vh;
  }

  .left_menu_tab {
    padding: 8% 10% 3% 6%;
  }
}